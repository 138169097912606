<template>
  <div class="demand-talent">
    <panel-title icon="el-icon-user" title="人才需求" />

    <panel-content>
      <div slot="filter" class="filter">
        <div class="top-filter">
          <span class="active">全部职位</span>
          <span>开放中</span>
          <span>待开放</span>
          <span>审核失败</span>
          <span>已关闭</span>
        </div>
        <div class="bottom-filter">
          <div>
            <el-button icon="el-icon-plus" size="medium" type="primary"
              >发布职位</el-button
            >
          </div>
          <div>
            <el-input
              size="medium"
              style="width: 220px"
              placeholder="请输入内容"
              prefix-icon="el-icon-search"
            />

            <el-select
              v-model="value"
              placeholder="请选择"
              style="width: 180px; margin-left: 15px"
              size="medium"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>

      <div class="list-wrap" slot="content">
        <div class="talent-item" v-for="i in 5" :key="i">
          <div class="left">
            <div class="title">
              电路设计工程师
              <span class="tag">普</span>
            </div>
            <div class="props-wrap">
              <span class="prop">深圳</span>
              <span class="prop">1-3年</span>
              <span class="prop">本科</span>
              <span class="prop">9-14K</span>
            </div>
          </div>
          <div class="center">
            <div class="item">
              <div class="count">89</div>
              <div class="desc">看过我</div>
            </div>
            <div class="item">
              <div class="count">89</div>
              <div class="desc">沟通过</div>
            </div>
            <div class="item">
              <div class="count">89</div>
              <div class="desc">感兴趣</div>
            </div>
            <div class="item">
              <span class="online-tag"></span>
              <span>开放中</span>
            </div>
          </div>
          <div class="right">
            <el-link :underline="false">编辑</el-link>
            <el-link :underline="false" style="margin: 0 10px">关闭</el-link>
            <el-link :underline="false">
              <i class="el-icon-more"></i>
            </el-link>
          </div>
        </div>
      </div>
    </panel-content>
  </div>
</template>

<script>
import PanelContent from "./PanelContent.vue";
import PanelTitle from "./PanelTitle.vue";
export default {
  components: { PanelTitle, PanelContent },

  data() {
    return {
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
        {
          value: "选项2",
          label: "双皮奶",
        },
        {
          value: "选项3",
          label: "蚵仔煎",
        },
        {
          value: "选项4",
          label: "龙须面",
        },
        {
          value: "选项5",
          label: "北京烤鸭",
        },
      ],
      value: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.demand-talent {
  .filter {
    .top-filter {
      span {
        cursor: pointer;
        display: inline-block;
        padding-right: 20px;
        margin-right: 20px;
        border-right: 1px solid #ccc;

        font-size: 14px;

        line-height: 17px;

        &:hover,
        &:active,
        &.active {
          color: red;
        }
      }

      span:last-child {
        border-right: none;
      }
    }

    .bottom-filter {
      margin-top: 15px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .list-wrap {
    .talent-item {
      font-size: 14px;
      padding: 20px;
      padding-left: 30px;
      border: 1px solid #ededed;
      display: flex;
      justify-content: space-between;
      align-items: center;

      & + .talent-item {
        margin-top: 20px;
      }

      .left {
        flex: 0 0 auto;
        width: 35%;
        .title {
          margin-bottom: 10px;
          .tag {
            font-size: 12px;
            width: 18px;
            height: 18px;
            line-height: 18px;
            text-align: center;
            display: inline-block;
            border: 1px solid green;
            color: green;
            border-radius: 4px;
          }
        }

        .props-wrap {
          .prop {
            color: #999;

            height: 14px;
            line-height: 14px;

            & + .prop {
              margin-left: 10px;
              padding-left: 10px;
              border-left: 1px solid #ededed;
            }
          }
        }
      }

      .center {
        flex: 1 1 auto;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .item {
          text-align: center;
          padding: 0 20px;

          .count {
            font-size: 16px;
          }

          .desc {
            margin-top: 10px;
            color: #999;
          }

          .online-tag {
            display: inline-block;
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: chartreuse;
            margin-right: 6px;
          }
        }
      }
    }
  }
}
</style>